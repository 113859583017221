<template>
    <div class="landing-layout">
        <main>
            <router-view />
        </main>
    </div>
</template>

<script>
export default {
    computed: {},
    data() {
        return {
            logoUrl: ''
        }
    },
    async created() {}
}
</script>

<style lang="scss" scoped></style>
